import CompanyApiCommon from '@jetCommon/api/company.js';

export default class CompanyApi extends CompanyApiCommon {
    updateCompanySettings(companyId, data) {
        return this.patch(`${companyId}/company_settings`, data);
    }

    retrieveSubscription(companyId, params) {
        return this.apiGet(`${companyId}/subscription/`, {params});
    }

    updateSubscription(companyId, data) {
        return this.patch(`${companyId}/subscription`, data);
    }

    importDataFromPe(companyId) {
        // Used in frontend/jet_backoffice/src/composables/last-import-summary.js
        return this.apiPost(`${companyId}/import_data_from_pe/`);
    }

    getSummary() {
        return this.apiGet('summary/');
    }

    sendInvitations(companyId) {
        return this.apiPost(`${companyId}/send_invitations/`);
    }

    importEmployeesFromExcel(companyId, data) {
        return this.apiPost(`${companyId}/import_employees_raw/`, data);
    }

    removeImportedData(companyId) {
        return this.apiPost(`${companyId}/delete_imported_employees_raw/`);
    }

    getLegalFormChoices() {
        return this.apiGet('legal_form_choices/');
    }

    getFirstPayrollDateChoices() {
        return this.apiGet('first_payroll_date_choices/');
    }

    getAssignedPayrollSpecialistChoices() {
        return this.apiGet('assigned_payroll_specialist_choices/');
    }

    getAssignedLabourConsultantChoices() {
        return this.apiGet('assigned_labour_consultant_choices/');
    }

    getOnboarding(companyId, params = {}) {
        return this.apiGet(`${companyId}/onboarding/`, {params});
    }

    updateOnboarding(companyId, data) {
        return this.apiPatch(`${companyId}/onboarding/`, data);
    }

    assign(companyId, data) {
        return this.patch(`${companyId}/assign`, data);
    }

    deactivate(companyId) {
        return this.apiPost(`${companyId}/deactivate/`);
    }

    activateAccounts(companyId) {
        return this.apiPost(`${companyId}/activate_accounts/`);
    }

    requestXlsDownload(params) {
        return this.apiGet('download_xlsx/', params);
    }

    getCompanyChoices(params = {}) {
        return this.apiGet('company_choices/', params);
    }

    getSubscriptionAddOn(companyId) {
        return this.apiGet(`${companyId}/subscription_add_on/`);
    }

    createSubscriptionAddOn(companyId, data = {}) {
        return this.apiPost(`${companyId}/subscription_add_on/`, data);
    }

    modifySubscriptionAddOn(companyId, data = {}) {
        return this.apiPatch(`${companyId}/subscription_add_on/`, data);
    }
}
