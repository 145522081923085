/* eslint-disable jethr/imports-order */
import App from './App.vue';
const app = createApp(App);

import '@jetDS/scss/main.scss';
import '@/assets/styles/jet-backoffice.scss';

import '../../fontawesome_icons.js';

const post_impersonification_redirect = sessionStorage.getItem('post_impersonification_redirect');
if (post_impersonification_redirect) {
    sessionStorage.removeItem('post_impersonification_redirect');
    window.location = post_impersonification_redirect;
}

import {createPinia} from 'pinia';
const pinia = createPinia();
app.use(pinia);

import router from './router.js';
app.use(router);

import {ElLoading} from 'element-plus';
import 'element-plus/es/components/loading/style/index';
app.directive('loading', ElLoading.directive);

import vueQueryConfig from '@jetCommon/queries/defaultQueryClientConfig.js';
import {VueQueryPlugin} from '@tanstack/vue-query';

app.use(VueQueryPlugin, vueQueryConfig);

import {initSentry} from '@jetCommon/sentry.js';
import settings from '@/env.js';
initSentry({
    app,
    router,
    sentrySettings: settings.SENTRY_SETTINGS,
    spaName: settings.SENTRY_TAG_SPA,
});

import '@jetCommon/handle-preload-error.js';

app.mount('#app');
